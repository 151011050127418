import React from "react";
import styles from "./kycPolicy.module.scss";

const KycPolicyEnglish = props => {
  return (
    <div>
      <h1>Privacy Policy for KYC Verification of Crew</h1>
      <div>
        <h2>Introduction</h2>
        <p>
          This Privacy Policy (hereinafter referred to as the "Policy") is
          established in compliance with applicable data protection laws and the
          guidelines outlined in Circular 11020/205/2017-AUTH-1. Zingbus is
          committed to ensuring the confidentiality, integrity, and security of
          the personal data provided by its internal users, including Marshals
          (Bus Hosts) and Drivers (Bus Drivers). By registering on the Zingbus
          platform, you acknowledge that you have read, understood, and agreed
          to the terms and conditions outlined in this Policy.
        </p>
      </div>
      <div>
        <h2>Scope of Application</h2>
        <p>
          This Policy governs the collection, use, processing, storage, &
          disclosure of personal data of:
          <ul>
            <li>Marshals registered with Zingbus</li>
            <li>Drivers registered with Zingbus.</li>
          </ul>
        </p>
      </div>
      <div>
        <h2>Categories of Personal Data Collected</h2>
        <p>
          Zingbus collects and processes the following categories of personal
          data for verification purposes:
          <h3>For Marshals:</h3>
          <ol>
            <li>
              <b>Aadhar Card:</b> For identity and address verification.
            </li>
            <li>
              <b>PAN Card:</b> For financial verification and compliance.
            </li>
          </ol>
          <h3>For Drivers:</h3>
          <ol>
            <li>
              <b>Aadhar Card:</b> For identity and address verification.
            </li>
            <li>
              <b>Driving License:</b> For identity verification and validation
              of driving credentials.
            </li>
          </ol>
        </p>
      </div>
      <div>
        <h2>Purpose of Data Processing</h2>
        <p>
          The personal data collected shall be processed strictly for the
          following purposes:
          <ol>
            <li>
              Identity and address verification in accordance with applicable
              legal and regulatory requirements;
            </li>
            <li>
              Credential validation to ensure passenger safety and compliance
              with operational standards.
            </li>
          </ol>
        </p>
      </div>
      <div>
        <h2>User Consent and Authorization</h2>
        <p>
          Prior to the collection and processing of personal data, Zingbus shall
          obtain explicit consent from the data subjects. By registering on the
          platform, you provide your informed and unequivocal consent to:
          <ul>
            <li>
              The collection, processing, and storage of your personal data as
              outlined in this Policy;
            </li>
            <li>
              Verification of your identity and credentials through authorized
              third-party agencies; and Retention of your personal data as
              mandated by applicable laws and regulations
            </li>
          </ul>
        </p>
      </div>
      <div>
        <h2>Data Protection and Security Measures</h2>
        <p>
          Zingbus employs stringent measures to protect the personal data of its
          internal users, including but not limited to:
          <ol>
            <li>
              Encryption protocols to secure data during transmission and
              storage;
            </li>
            <li>
              Role-based access controls ensuring that only authorized personnel
              have access to personal data;
            </li>
            <li>
              Periodic audits to ensure compliance with the Circular Reference:
              Circular 11020/205/2017-AUTH-1 and relevant data protection laws.
            </li>
          </ol>
          Zingbus guarantees that your personal data will be maintained in a
          secure environment and will not be used for any purpose other than
          those specified in this Policy.
        </p>
      </div>
      <div>
        <h2>Data Sharing and Disclosure</h2>
        <p>
          Personal data may be shared only under the following circumstances:
          <ol>
            <li>
              With authorized agencies for the purpose of identity and
              credential verification;
            </li>
            <li>With regulatory authorities as mandated by law.</li>
          </ol>
          Zingbus expressly prohibits the sharing of personal data with third
          parties for marketing or any unrelated purposes.
        </p>
      </div>
      <div>
        <h2>Data Retention Policy</h2>
        <p>
          Personal data will be retained for the duration of your engagement
          with Zingbus and any additional period as required to comply with
          legal or regulatory obligations.
        </p>
      </div>
      <div>
        <h2>Rights of Data Subjects</h2>
        <p>
          As a data subject, you have the following rights with respect to your
          personal data:
          <ol>
            <li>Right to access your personal data held by Zingbus;</li>
            <li>
              Right to request rectification of inaccurate or incomplete data;
            </li>
            <li>
              Right to withdraw consent for processing, subject to applicable
              legal limitations.
            </li>
          </ol>
          Requests to exercise your rights may be submitted to care@zingbus.com.
        </p>
      </div>
      <div>
        <h2>Amendments to the Policy</h2>
        <p>
          Zingbus reserves the right to amend or update this Policy to reflect
          changes in legal requirements or internal procedures. All updates will
          be communicated to registered users via email or the Zingbus platform.
        </p>
      </div>
      <div>
        <h2>Contact Information</h2>
        <p>For inquiries or concerns regarding this Policy, please contact:</p>
        <p>
          <b>Zingbus Privacy Compliance Officer</b> Email: care@zingbus.com
          Address: Good Earth Business Bay, 1, Golf Course Rd Ext, Sector 58,
          Gurugram, Haryana 122003
        </p>
        <hr />
        <p>
          By registering on the Zingbus platform, you affirm that you have read
          and understood this Policy and consent to the processing of your
          personal data as outlined herein and in accordance with the Circular
          Reference: 11020/205/2017-AUTH-1.
        </p>
      </div>
    </div>
  );
};

export default KycPolicyEnglish;
