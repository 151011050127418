import React, { Component } from "react";
import moment from "moment";
import { Modal, Table, Button, message, Radio, Tag } from "antd";
import SendMessage from "../sendMessage";
import Fetch from "../../utils/fetch";
import { getHoursAndMinFromMillis } from "../../utils/common";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "10%",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: data => <Tag color={data.color}>{data.value}</Tag>
  },
  {
    title: "Schedule Reaching Time",
    dataIndex: "reachingTime",
    key: "reachingTime",
    render: date => (date ? moment(date).format("LLL") : null),
    width: "10%"
  },
  {
    title: "ETA",
    dataIndex: "eta",
    key: "eta",
    width: "10%"
  },
  {
    title: "Reached At",
    dataIndex: "reachedTime",
    key: "reachedTime",
    render: date => (date ? moment(date).format("LLL") : null),
    width: "10%"
  },
  // {
  //   title: "Waiting Time",
  //   dataIndex: "waitTime",
  //   key: "waitTime",
  //   render: date => (date ? date : null),
  //   width: "10%"
  // },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: ({ stationMarked, station, onClick, tripStatus }) => {
      if (tripStatus !== "COMPLETED") {
        return (
          <Button
            onClick={() => onClick(station, !stationMarked ? "MARK" : "UNMARK")}
            type="danger"
          >
            {!stationMarked ? "Mark Station" : "Unmark Station"}
          </Button>
        );
      }
    },
    width: "10%"
  }
];

class StationList extends Component {
  state = {
    loading: true,
    stationList: [],
    radioSelected: 0,
    selectedStation: [],
    selectedRowKeys: []
  };

  async componentDidMount() {
    try {
      const stationList = await this.fetchStation();

      this.setState({
        loading: false,
        stationList
      });
    } catch (err) {
      console.log(err);
    }
  }

  millisToMinutesAndSeconds = millis => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes} mins :${seconds < 10 ? "0" : ""}${seconds} sec`;
  };

  fetchStation = async () => {
    try {
      const { tripId, tripStatus } = this.props;
      const url = `/bus-tracker/stations/${tripId}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        const stationList = response.map(station => {
          // let stationWaitTime = station.waitTime
          //   ? this.millisToMinutesAndSeconds(station.waitTime)
          //   : null;
          const status = { value: "ON TIME", color: "green" };
          const timeDiff = station.expectedReachingTime
            ? station.expectedReachingTime - station.scheduleReachingTime
            : null;

          if (!timeDiff) {
            status.value = `Expected Reaching time not found`;
            status.color = "red";
          }

          if (timeDiff !== null && timeDiff > 0) {
            status.value = `DELAYED by ${getHoursAndMinFromMillis(timeDiff)}`;
            status.color = "#f50";
          }

          if (station.stationMarked) {
            status.value = "REACHED";
          }

          if (tripStatus === "ACTIVE") {
            status.value = "Trip Not Started Yet";
            status.color = "magenta";
          }
          return {
            name: station.station.name,
            action: {
              stationMarked: station.stationMarked,
              station: station.station,
              onClick: this.markStation,
              tripStatus
            },
            reachingTime: station.scheduleReachingTime,
            eta: station.expectedReachingTime
              ? getHoursAndMinFromMillis(
                  station.expectedReachingTime - Date.now()
                )
              : null,
            reachedTime: station.reachedTime,
            // waitTime: stationWaitTime,
            status,
            key: station.station._id,
            _id: station.station._id
          };
        });

        return Promise.resolve(stationList);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  markStation = async (station, status) => {
    try {
      const { tripId, tripStatus } = this.props;
      const { stationList } = this.state;

      const url = `/bus-tracker/markstation/`;
      const options = {
        method: "post",
        data: {
          stationId: station._id,
          lng: parseFloat(station.lng),
          lat: parseFloat(station.lat),
          stationName: station.name,
          tripId,
          status
        }
      };

      const response = await Fetch(url, options);

      if (response) {
        message.success(`Station ${status} success`);
        const updatedStationList = stationList.map(s => {
          if (s._id === response[0].stationId) {
            if (status === "MARK") {
              return {
                ...s,
                status: { value: "REACHED", color: "green" },
                action: { ...s.action, stationMarked: true, tripStatus }
              };
            } else if (status === "UNMARK") {
              return {
                ...s,
                status: { value: "NA", color: "orange" },
                action: { ...s.action, stationMarked: false, tripStatus }
              };
            }
          }
          return s;
        });

        this.setState({
          stationList: updatedStationList
        });

        return Promise.resolve(response);
      } else {
        // console.log(response);
        throw new Error("No response");
      }
    } catch (err) {
      console.log(err);
    }
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  sendMessage = async (
    templateType,
    vars,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { selectedStation, radioSelected, stationList } = this.state;
      const { tripId } = this.props;
      // console.log("====vars====", templateType, vars);

      if (selectedStation.length === stationList.length) {
        const url = `/bus-tracker/sendmessageandwhatsapp`;

        const options = {
          method: "post",
          data: {
            stations: selectedStation,
            stationType: radioSelected === 1 ? "PICKUP" : "DROP",
            tripId,
            templateType,
            vars,
            sendSMSMessage,
            sendWhatsAppMessage
            // addTrackingLink,
            // message: text
          }
        };

        const response = await Fetch(url, options);
        // console.log("response.length", response.length);
        // console.log("response", response);
        if (response) {
          message.success("Message sent successfully");
          this.setState({
            selectedStation: [],
            radioSelected: 0,
            selectedRowKeys: []
          });
        }
      } else if (selectedStation.length && radioSelected) {
        const url = `/bus-tracker/sendmessageandwhatsapp`;

        const options = {
          method: "post",
          data: {
            stations: selectedStation,
            stationType: radioSelected === 1 ? "PICKUP" : "DROP",
            tripId,
            templateType,
            vars,
            sendSMSMessage,
            sendWhatsAppMessage
            // addTrackingLink,
            // message: text
          }
        };

        const response = await Fetch(url, options);
        // console.log("response", response);
        if (response) {
          message.success("Message sent successfully");
          this.setState({
            selectedStation: [],
            radioSelected: 0,
            selectedRowKeys: []
          });
        }
      } else if (!radioSelected) {
        message.error("Please select Pickup or Drop button Correctly");
      } else {
        message.error("No Staions are selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  render() {
    const {
      loading,
      stationList,
      radioSelected,
      selectedRowKeys,
      selectedStation
    } = this.state;

    return (
      <Modal
        title="Station List"
        visible={true}
        onCancel={this.props.onCancel}
        footer={null}
        width={"90%"}
      >
        {loading ? (
          <h1>loading ...</h1>
        ) : (
          <>
            <div style={{ display: "flex", margin: "2% 0" }}>
              <SendMessage
                stationType={radioSelected}
                tripId={this.props.tripId}
                selectedStation={selectedStation}
                onClick={this.sendMessage}
                selectedCount={selectedStation.length}
              />
              <div style={{ marginLeft: "1%", paddingTop: "5px" }}>
                <Radio.Group
                  onChange={e => this.onChange("radioSelected", e.target.value)}
                  value={radioSelected}
                >
                  <Radio value={1}>Pickup</Radio>
                  <Radio value={2}>Drop</Radio>
                </Radio.Group>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={stationList}
              rowSelection={{
                columnWidth: "10%",
                selectedRowKeys,
                onChange: keys =>
                  this.setState({
                    selectedStation: keys,
                    selectedRowKeys: keys
                  })
              }}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </>
        )}
      </Modal>
    );
  }
}

export default StationList;
