import React, { Component } from "react";
import { Select, Table, DatePicker, Modal, message, Tooltip } from "antd";

import Loader from "../../loader";
import Fetch from "../../../utils/fetch";
import Search from "../operatorSearch";
import moment from "moment";
import less from "./trips.module.scss";
import jwt from "jsonwebtoken";
import { getSeatNumberFromTripChart } from "../../../utils/common";

const { Option } = Select;

const columns = [
  Table.EXPAND_COLUMN,
  {
    title: "Route",
    dataIndex: "route",
    key: "route",
    width: "10%",
    render: ({ fromCity, toCity }) => (
      <>
        <span>{fromCity}</span>
        <br />
        <span>{toCity}</span>
      </>
    )
  },
  {
    title: "Trip ID",
    dataIndex: "tripId",
    key: "tripId",
    width: "15%",
    render: ({ value, copy }) => (
      <div className={less.tripTable}>
        <span>{value}</span>
        <img
          onClick={() => copy(value)}
          src="https://d1flzashw70bti.cloudfront.net/original/images/tarcer/copy.svg"
          alt="copy"
        />
      </div>
    )
  },
  {
    title: "Start Time & Date",
    dataIndex: "date",
    key: "date",
    width: "10%"
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: "10%"
  },
  {
    title: "Bookings",
    dataIndex: "booking",
    key: "booking",
    width: "10%",
    render: ({ value }) => (
      <div>
        <span>{value}</span>
      </div>
    )
  },
  {
    title: "Asset Type",
    dataIndex: "assetType",
    key: "assetType",
    width: "10%"
  },
  {
    title: "Operator",
    dataIndex: "operator",
    key: "operator",
    width: "10%"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ value }) => (
      <div className={less.statusDiv}>
        <div
          className={
            value === "ACTIVE" ? less.statusActive : less.statusInActive
          }
        ></div>
        <span>{value}</span>
      </div>
    )
  },
  {
    title: "Assign",
    dataIndex: "assign",
    key: "assign",
    width: "15%",
    render: ({ assign, _id, isAssigned, driverDetails }) => (
      <div className={less.assignTable}>
        {isAssigned ? (
          <div className={less.isAssigned}>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/accept.svg"
              alt="accept"
            />
            <span>Assigned</span>
            <Tooltip
              title={driverDetails}
              overlayStyle={{
                backgroundColor: "transparent", // Make background transparent
                borderRadius: "8px", // Add border-radius
                border: "1px solid rgba(0, 0, 0, 0.1)" // Optional: add a subtle border if you want
              }}
              trigger={"click"}
            >
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/tooltip.svg"
                alt="tooltip"
              />
            </Tooltip>
          </div>
        ) : (
          <div className={less.notAssigned} onClick={() => assign(_id)}>
            {" "}
            <span>Assign</span>
          </div>
        )}
      </div>
    )
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "10%",
    render: ({ edit, logs, _id }) => (
      <div className={less.editTable}>
        <div onClick={() => edit(_id)}>
          <span>Edit</span>
        </div>
        <div onClick={() => logs(_id)}>
          <span>Logs</span>
        </div>
      </div>
    )
  }
];

class OperatorCabTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      operatorTableData: [],
      expandedRowsData: {},
      showEditModal: false,
      showCreateModal: false,
      editData: {},
      showLogModal: false,
      expandedRowKeys: [],
      cities: []
    };
  }

  async componentDidMount() {
    try {
      const response = await this.fetchData();
      const cabOperators = await this.fetchOperators();

      const { cities } = this.props;
      const activeCities = cities.filter(city => city.status == "ACTIVE");
      this.setState({
        cabOperators,
        operatorTableData: response.data,
        operatorData: response.allResponse,
        loading: false,
        cities: activeCities
      });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }
  fetchData = async () => {
    try {
      const url = `/cabOperator/trips`;

      const options = {
        method: "get"
      };
      let allResponse = await Fetch(url, options);
      let data = [];

      if (Array.isArray(allResponse) && allResponse.length > 0) {
        data = this.formatData(allResponse);
      } else {
        allResponse = [];
      }

      return { data, allResponse };
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  formatData = values => {
    let tableData = values.map(value => {
      let data = {
        key: value._id,
        route: {
          fromCity: value.fromCity.name,
          toCity: value.toCity.name
        },
        tripId: { value: value.groupCode, copy: this.copyData },
        date: moment(value.startTime).format("h:mm a, DD MMM YY"),
        type: value.isIntraCity ? "Intra" : "Inter",
        booking: { value: value.bookings.length },
        assetType: value.assetType,
        operator: value.cabOperator ? value.cabOperator.name : null,
        status: {
          value: value.status
        },
        assign: {
          assign: this.assignToOperator,
          isAssigned: value.assignedToOperator,
          _id: value._id,
          driverDetails: this.getDriverDetails()
        },
        actions: {
          _id: value._id,
          edit: this.editData,
          logs: this.showLogs
        },
        bookings: value.bookings
      };
      return data;
    });
    return tableData;
  };

  handleExpandedRowRender = record => {
    const data = this.listData(record.bookings);
    return data;
  };

  handleRowExpand = (expanded, record) => {
    this.setState(prevState => {
      const { expandedRowKeys } = prevState;
      if (expanded) {
        return { expandedRowKeys: [...expandedRowKeys, record.key] };
      } else {
        return {
          expandedRowKeys: expandedRowKeys.filter(key => key !== record.key)
        };
      }
    });
  };

  listData = bookings => {
    let x = 1;
    if (!bookings) {
      return <span>No Booking found</span>;
    } else
      return bookings.map((booking, index) => (
        <div key={index} className={less.listData}>
          <div>
            <span>{x++}</span>
          </div>
          <div>
            <span>PNR : </span>
            <span>{booking.bookingCode}</span>
          </div>
          <div>
            <span>Seats : </span>
            <span>{getSeatNumberFromTripChart(booking.tripChart)}</span>
          </div>
          <div className={less.stationDetail}>
            <div>
              <span>Pickup Station : </span>
              <span>
                {booking.fromStation ? booking.fromStation.name : null}
              </span>
            </div>
            <div>
              <span>Drop Station : </span>
              <span>{booking.toStation ? booking.toStation.name : null}</span>
            </div>
          </div>
          <div className={less.status}>
            <span>{booking.status} </span>
          </div>
          <div className={less.stationDetail}>
            <div>
              <span>Pickup Time : </span>
              <span>
                {moment(parseInt(booking.fromStationDate)).format("h:mm a")}
              </span>
            </div>
            <div>
              <span>Drop Time : </span>
              <span>
                {" "}
                {moment(parseInt(booking.toStationDate)).format("h:mm a")}
              </span>
            </div>
          </div>
          <div className={less.remove}>
            <span
              onClick={() =>
                this.removeTrip(booking._id, booking.cabBookingGroupId)
              }
            >
              Remove
            </span>
          </div>
        </div>
      ));
  };

  removeTrip = async (bookingId, cabGroupId) => {
    const { operatorData } = this.state;
    let body = {
      cabBookingGroupId: cabGroupId,
      bookingIdsToRemove: [bookingId]
    };
    const url = `/cabOperator/removeTripToBooking`;

    const options = {
      method: "post",
      data: { details: body }
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response)) {
      const updatedOperatorData = operatorData.map(operator => {
        const updatedResponse = response.find(
          item => item._id === operator._id
        );

        if (updatedResponse) {
          return updatedResponse; // replace operator with updatedResponse
        }
        return operator;
      });

      const data = this.formatData(updatedOperatorData);

      this.setState({
        operatorTableData: data,
        operatorData: updatedOperatorData
      });
      message.success("Booking is Removed");
    } else {
      message.error(response);
    }
  };

  fetchAssetType = async () => {
    const url = `/cabOperator/getAssetType`;

    const options = {
      method: "get"
    };
    const response = await Fetch(url, options);
    if (response) {
      return response;
    } else {
      return [];
    }
  };

  showCreateTripModal = async () => {
    const assetTypeValues = await this.fetchAssetType();
    this.setState({
      showCreateModal: true,
      tripType: "inter",
      cabOperatorId: undefined,
      assetTypeValues,
      interValue: {},
      cabOperator: null,
      intraValue: {}
    });
  };

  getDriverDetails = data => {
    return (
      <div className={less.driverDetail}>
        <div>
          <div>
            <span>RC</span>
            <span>OD02XX6789</span>
          </div>
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/copy.svg"
            alt="copy"
          />
        </div>
        <div>
          <div>
            <span>Mobile</span>
            <span>9776334477</span>
          </div>
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/copy.svg"
            alt="copy"
          />
        </div>
        <div>
          <div>
            <span>Name</span>
            <span>John Doe</span>
          </div>
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/copy.svg"
            alt="copy"
          />
        </div>
      </div>
    );
  };

  handleChange = async (key, value, data) => {
    let stations = [];
    if (key === "tripType" && value === "intra") {
      stations = await this.fetchStations();
    }
    if (key === "cabOperatorId") {
      this.setState({ [key]: data.key, cabOperator: value });
    } else this.setState({ [key]: value, stations });
  };

  fetchStations = async () => {
    try {
      const url = `/bus-tracker/getStationsCache`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  fetchOperators = async () => {
    const url = `/cabOperator/getOperator`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    return response;
  };
  createCabGroup = async () => {
    const {
      cabOperatorId,
      assetType,
      interValue,
      intraValue,
      tripType
    } = this.state;
    let details = {
      cabOperatorId,
      assetType
    };
    if (tripType == "inter") {
      details = {
        ...details,
        ...interValue
      };
    } else {
      details = {
        ...details,
        ...intraValue
      };

      details = {
        cabOperatorId: details.cabOperatorId,
        assetType: details.assetType,
        fromStationId: details.fromStationId,
        toStationId: details.toStationId,
        startTime: details.startTime,
        isIntraCity: true,
        fromCityId: details.cityId,
        toCityId: details.cityId
      };
    }

    const url = `/cabOperator/createCabGroup`;

    const options = {
      method: "post",
      data: { details }
    };

    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      this.setState({
        showCreateModal: false,
        showConfirmationModal: true
      });
    } else {
      this.setState({
        showCreateModal: false,
        showErrorModal: true
      });
    }
  };

  setInterCityValues = (key, value, data) => {
    let { interValue = {} } = this.state;
    let newInterValue = interValue;

    if (key === "toCityId") {
      newInterValue = {
        ...interValue,
        toCity: value,
        toCityId: data.key
      };
      this.setState({ interValue: newInterValue });
    } else if (key === "fromCityId") {
      newInterValue = {
        ...interValue,
        fromCity: value,
        fromCityId: data.key
      };
      this.setState({ interValue: newInterValue });
    } else {
      interValue[key] = value;
      this.setState({ interValue });
    }
  };

  setIntraCityValues = (key, value, data) => {
    let { intraValue = {}, stations } = this.state;
    let newIntraValue = intraValue;

    if (key === "city") {
      let cityId = data.key;
      let intraStations = stations.filter(station => station.cityId == cityId);
      newIntraValue = {
        ...newIntraValue,
        cityId,
        fromStationName: undefined,
        toStationName: undefined,
        city: value
      };
      this.setState({ intraValue: newIntraValue, intraStations });
    } else if (key === "fromStationId") {
      newIntraValue = {
        ...intraValue,
        fromStationName: value,
        fromStationId: data.key
      };
      this.setState({ intraValue: newIntraValue });
    } else if (key === "toStationId") {
      newIntraValue = {
        ...newIntraValue,
        toStationName: value,
        toStationId: data.key
      };
      this.setState({ intraValue: newIntraValue });
    } else {
      intraValue[key] = value;

      this.setState({ intraValue });
    }
  };

  onOk = value => {
    const { tripType } = this.state;
    const date = new Date(value);
    const millis = date.getTime();
    if (tripType == "inter") {
      this.setInterCityValues("startTime", millis);
    } else {
      this.setIntraCityValues("startTime", millis);
    }
  };

  editValue = async (key, value, data) => {
    const { editData, stations, updateBody = {} } = this.state;
    debugger;
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);

    let remark = updateBody.remark ? `${updateBody.remark} ||| ` : "";
    let updatedEditData = editData;
    let tempBody = updateBody;

    if (key === "toCityId") {
      remark += `To City is Updated from ${
        updatedEditData.toCity.name
      } to ${value} by ${decodedToken.name} at ${moment().format("LLL")}`;

      updatedEditData = {
        ...updatedEditData,
        toCity: {
          name: value,
          _id: data.key
        }
      };
      tempBody = {
        ...tempBody,
        toCityId: data.key,
        remark
      };

      this.setState({ editData: updatedEditData, updateBody: tempBody });
    } else if (key === "fromCityId") {
      remark += `From City is Updated from ${
        updatedEditData.fromCity.name
      } to ${value} by ${decodedToken.name} at ${moment().format("LLL")}`;

      updatedEditData = {
        ...updatedEditData,
        fromCity: {
          name: value,
          _id: data.key
        }
      };
      tempBody = {
        ...tempBody,
        fromCityId: data.key,
        remark
      };
      this.setState({ editData: updatedEditData, updateBody: tempBody });
    } else if (key === "city") {
      let cityId = data.key;
      let intraStations = stations.filter(station => station.cityId == cityId);
      remark += `City is Updated from ${
        updatedEditData.fromCity.name
      } to ${value} by ${decodedToken.name} at ${moment().format("LLL")}`;
      updatedEditData = {
        ...updatedEditData,
        cityId,
        fromStation: {},
        toStation: {},
        fromCity: {
          name: value,
          _id: data.key
        },
        toCity: {
          name: value,
          _id: data.key
        }
      };
      tempBody = {
        ...tempBody,
        cityId: data.key,
        remark
      };
      this.setState({
        editData: updatedEditData,
        intraStations,
        updateBody: tempBody
      });
    } else if (key === "fromStationId") {
      remark += `From Station is Updated from ${
        updatedEditData.fromStation.name
      } to ${value} by ${decodedToken.name} at ${moment().format("LLL")}`;

      updatedEditData = {
        ...updatedEditData,
        fromStation: {
          name: value,
          _id: data.key
        }
      };
      tempBody = {
        ...tempBody,
        fromStationId: data.key,
        remark
      };
      this.setState({ editData: updatedEditData, updateBody: tempBody });
    } else if (key === "toStationId") {
      remark += `From Station is Updated from ${
        updatedEditData.toStation.name
      } to ${value} by ${decodedToken.name} at ${moment().format("LLL")}`;

      updatedEditData = {
        ...updatedEditData,
        toStation: {
          name: value,
          _id: data.key
        }
      };
      tempBody = {
        ...tempBody,
        toStationId: data.key,
        remark
      };
      this.setState({ editData: updatedEditData, updateBody: tempBody });
    } else if (key === "startTime") {
      remark += `startTime is Updated from ${moment(
        updatedEditData.startTime
      ).format("h:mm a, DD MMM YY")} to ${moment(value).format(
        "h:mm a, DD MMM YY"
      )} by ${decodedToken.name} at ${moment().format("LLL")}`;

      editData[key] = value;
      tempBody = {
        ...tempBody,
        startTime: value,
        remark
      };

      this.setState({ editData, updateBody: tempBody });
    } else if (key === "status") {
      remark += `status is Updated from ${
        updatedEditData.status
      } to ${value} by ${decodedToken.name} at ${moment().format("LLL")}`;

      editData[key] = value;
      tempBody = {
        ...tempBody,
        status: value,
        remark
      };
      this.setState({ editData, updateBody: tempBody });
    }
  };

  onEditOk = value => {
    const date = new Date(value);
    const millis = date.getTime();
    this.editValue("startTime", millis);
  };

  updateGroup = async () => {
    const { updateBody, editData, operatorData } = this.state;

    const url = `/cabOperator/updateGroup`;

    const options = {
      method: "patch",
      data: { updateBody, _id: editData._id }
    };

    const response = await Fetch(url, options);

    if (Array.isArray(response) && response.length > 0) {
      const updatedOperatorData = operatorData.map(operator => {
        const updatedResponse = response.find(
          item => item._id === operator._id
        );

        if (updatedResponse) {
          return { ...operator, ...updatedResponse };
        }
        return operator;
      });
      const data = this.formatData(updatedOperatorData);

      this.setState({
        operatorTableData: data,
        operatorData: updatedOperatorData,
        showEditModal: false
      });
    }
  };

  editData = async _id => {
    let stateObj = { showEditModal: true };
    const { operatorData } = this.state;
    const editData = operatorData.filter(data => data._id == _id)[0];

    if (editData.isIntraCity) {
      const stations = await this.fetchStations();
      const intraStations = stations.filter(
        station => station.cityId == editData.cityId
      );
      stateObj.stations = stations;
      stateObj.intraStations = intraStations;
    }
    stateObj.editData = editData;
    this.setState(stateObj);
  };
  copyData = value => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        message.success("Value copied: " + value);
      })
      .catch(err => {
        console.error("Error copying text: ", err);
      });
  };
  showLogs = _id => {
    let stateObj = { showLogModal: true };
    const { operatorData } = this.state;
    const logs = operatorData.filter(data => data._id == _id)[0].remark;
    if (logs) {
      const statusParts = logs.split(" ||| ");

      const regex = /(.+?) is Updated from (.+?) to (.+?) by (\w+) at (.+)/;

      let parts = statusParts
        .map(status => {
          const match = status.match(regex);

          if (match) {
            return {
              value: match[1],
              oldValue: match[2],
              newValue: match[3],
              actor: match[4],
              time: match[5]
            };
          }
          return null;
        })
        .filter(item => item !== null);
      const logsColoumn = [
        {
          title: " Value",
          dataIndex: "value",
          key: "value"
        },
        {
          title: "Old Value",
          dataIndex: "oldValue",
          key: "oldValue"
        },
        {
          title: "Updated Value",
          dataIndex: "newValue",
          key: "newValue"
        },
        {
          title: "Actor",
          dataIndex: "actor",
          key: "actor"
        },
        {
          title: "Time",
          dataIndex: "time",
          key: "time"
        }
      ];
      stateObj.logsColoumn = logsColoumn;
      stateObj.parts = parts;

      this.setState(stateObj);
    } else {
      message.error("No Logs Found");
    }
  };

  assignToOperator = async _id => {
    const { operatorData } = this.state;
    const updateBody = {
      assignedToOperator: true
    };
    const url = `/cabOperator/updateGroup`;

    const options = {
      method: "patch",
      data: { updateBody, _id: _id }
    };
    const response = await Fetch(url, options);

    if (Array.isArray(response) && response.length > 0) {
      const updatedOperatorData = operatorData.map(operator => {
        const updatedResponse = response.find(
          item => item._id === operator._id
        );

        if (updatedResponse) {
          return { ...operator, ...updatedResponse };
        }
        return operator;
      });
      const data = this.formatData(updatedOperatorData);

      this.setState({
        operatorTableData: data,
        operatorData: updatedOperatorData,
        showEditModal: false
      });
    }
  };

  searchData = response => {
    const data = this.formatData(response);
    this.setState({
      operatorTableData: data,
      operatorData: response
    });
  };

  render() {
    const {
      loading,
      contentLoading,
      operatorTableData,
      cities,
      editData = {},
      showEditModal,
      interValue = {},
      cabOperator,
      showCreateModal,
      intraStations = [],
      tripType,
      intraValue = {},
      cabOperators = [],
      assetTypeValues = [],
      assetType,
      showConfirmationModal,
      showErrorModal,
      showLogModal,
      parts,
      logsColoumn
    } = this.state;

    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div style={{ marginBottom: "10px" }}>
            <Search
              cabOperators={cabOperators}
              showLoader={this.showLoading}
              cities={cities}
              showCreateModal={this.showCreateTripModal}
              onSearch={this.searchData}
            />
          </div>
          <hr />
          <Table
            // scroll={{ y: "90vh" }}
            scroll={{ y: "90vh", x: "120%" }}
            loading={contentLoading}
            columns={columns}
            pagination={false}
            dataSource={operatorTableData}
            expandable={{
              expandedRowRender: record => this.handleExpandedRowRender(record),
              onExpand: this.handleRowExpand
            }}
          />
          {showEditModal ? (
            <Modal
              closable={false}
              visible={showEditModal}
              onCancel={() => this.setState({ showEditModal: false })}
              onOk={this.updateGroup}
            >
              <div className={less.createModal}>
                <div className={less.heading}>
                  <span>Edit Trip</span>
                  <div>
                    <span>
                      {editData.fromCity.name} {editData.toCity.name}
                    </span>
                    <span>{moment(editData.startTime).format("h:mm a")}</span>
                  </div>
                </div>
                <div>
                  <span>Connecting Type</span>
                  <Select
                    disabled
                    style={{ width: 450 }}
                    defaultValue={editData.isIntraCity ? "intra" : "inter"}
                    placeholder="Select"
                  >
                    <Option value="inter">Inter</Option>
                    <Option value="intra">Intra</Option>
                  </Select>
                </div>
                {!editData.isIntraCity ? (
                  <div className={less.typeSelector}>
                    <div>
                      <span>From City</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        value={editData.fromCity.name}
                        onChange={(value, data) =>
                          this.editValue("fromCityId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map(city => (
                          <Option value={city.name} key={city._id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <span>To City</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        value={editData.toCity.name}
                        onChange={(value, data) =>
                          this.editValue("toCityId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map(city => (
                          <Option value={city.name} key={city._id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : (
                  <div className={less.typeSelector}>
                    <div>
                      <span>City</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        value={editData.fromCity.name}
                        onChange={(value, data) =>
                          this.editValue("city", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map(city => (
                          <Option value={city.name} key={city._id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <span>From Station</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        placeholder="Select"
                        value={editData.fromStation.name}
                        onChange={(value, data) =>
                          this.editValue("fromStationId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {intraStations.map(station => (
                          <Option value={station.name} key={station._id}>
                            {station.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <span>To Station</span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        placeholder="Select"
                        value={editData.toStation.name}
                        onChange={(value, data) =>
                          this.editValue("toStationId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {intraStations.map(station => (
                          <Option value={station.name} key={station._id}>
                            {station.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}
                <div>
                  <span>Date and Start Time</span>
                  <DatePicker
                    style={{ width: 450 }}
                    showTime={{
                      format: "HH:mm"
                    }}
                    value={moment(editData.startTime)}
                    format="DD-MM-YYYY HH:mm"
                    showNow={false}
                    onOk={this.onEditOk}
                  />
                </div>
                <div className={less.edit}>
                  <div>
                    <span>Asset Type</span>
                    <Select
                      style={{ width: 200 }}
                      value={editData.assetType}
                      placeholder="Select"
                      onChange={value => this.editValue("assetType", value)}
                    >
                      {assetTypeValues.map(asset => (
                        <Option value={asset.name} key={asset.id}>
                          {asset.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <span>Trip Status</span>
                    <Select
                      style={{ width: 200 }}
                      value={editData.status}
                      placeholder="Select"
                      onChange={value => this.editValue("status", value)}
                    >
                      <Option value="ACTIVE">ACTIVE</Option>
                      <Option value="INACTIVE">INACTIVE</Option>
                    </Select>
                  </div>
                </div>

                <div>
                  <span>Operator</span>
                  <Select
                    disabled={editData.assignedToOperator}
                    showSearch
                    style={{ width: 450 }}
                    onChange={(value, data) =>
                      this.editValue("cabOperatorId", value, data)
                    }
                    value={editData.cabOperator.name}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cabOperators.length > 0
                      ? cabOperators.map(operator => (
                          <Option value={operator.value} key={operator._id}>
                            {operator.value}
                          </Option>
                        ))
                      : null}
                  </Select>
                </div>
              </div>
            </Modal>
          ) : null}

          <Modal
            title="Create a Cab Trip"
            visible={showCreateModal}
            onCancel={() => this.setState({ showCreateModal: false })}
            onOk={this.createCabGroup}
          >
            <div className={less.createModal}>
              <div>
                <span>Connecting Type</span>
                <Select
                  style={{ width: 450 }}
                  value={tripType}
                  placeholder="Select"
                  onChange={value => this.handleChange("tripType", value)}
                >
                  <Option value="inter">Inter</Option>
                  <Option value="intra">Intra</Option>
                </Select>
              </div>
              {tripType == "inter" ? (
                <div className={less.typeSelector}>
                  <div>
                    <span>From City</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      value={interValue.fromCity}
                      onChange={(value, data) =>
                        this.setInterCityValues("fromCityId", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map(city => (
                        <Option value={city.name} key={city._id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <span>To City</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      value={interValue.toCity}
                      onChange={(value, data) =>
                        this.setInterCityValues("toCityId", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map(city => (
                        <Option value={city.name} key={city._id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ) : (
                <div className={less.typeSelector}>
                  <div>
                    <span>City</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      value={intraValue.city}
                      onChange={(value, data) =>
                        this.setIntraCityValues("city", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map(city => (
                        <Option value={city.name} key={city._id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <span>From Station</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      placeholder="Select"
                      value={intraValue.fromStationName}
                      onChange={(value, data) =>
                        this.setIntraCityValues("fromStationId", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {intraStations.map(station => (
                        <Option value={station.name} key={station._id}>
                          {station.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <span>To Station</span>
                    <Select
                      showSearch
                      style={{ width: 450 }}
                      placeholder="Select"
                      value={intraValue.toStationName}
                      onChange={(value, data) =>
                        this.setIntraCityValues("toStationId", value, data)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {intraStations.map(station => (
                        <Option value={station.name} key={station._id}>
                          {station.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
              <div>
                <span>Date and Start Time</span>
                <DatePicker
                  style={{ width: 450 }}
                  showTime={{
                    format: "HH:mm"
                  }}
                  value={
                    interValue.startTime
                      ? moment(interValue.startTime)
                      : intraValue.startTime
                      ? moment(intraValue.startTime)
                      : null
                  }
                  format="DD-MM-YYYY HH:mm"
                  showNow={false}
                  onOk={this.onOk}
                />
              </div>
              <div>
                <span>Asset Type</span>
                <Select
                  style={{ width: 450 }}
                  value={assetType}
                  placeholder="Select"
                  onChange={value => this.handleChange("assetType", value)}
                >
                  {assetTypeValues.map(asset => (
                    <Option value={asset.name} key={asset.id}>
                      {asset.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <span>Operator</span>
                <Select
                  showSearch
                  style={{ width: 450 }}
                  onChange={(value, data) =>
                    this.handleChange("cabOperatorId", value, data)
                  }
                  value={cabOperator}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {cabOperators.map(operator => (
                    <Option value={operator.value} key={operator._id}>
                      {operator.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </Modal>

          <Modal
            centered
            visible={showConfirmationModal}
            footer={null}
            // closable={false}
          >
            <div className={less.modalMessages}>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/modal/check.svg"
                alt="ok"
              />
              <div>
                <span>Creation Successful</span>
                <span>Cab trip has been successfully created</span>
              </div>
              <button
                onClick={() =>
                  this.setState({
                    showConfirmationModal: false
                  })
                }
              >
                <span>OK</span>
              </button>
            </div>
          </Modal>
          <Modal visible={showErrorModal} centered footer={null}>
            <div className={less.modalMessages}>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/modal/error.svg"
                alt="error"
              />
              <div>
                <span>Creation Failed</span>
                <span>Sorry, we couldn't create a cab trip</span>
              </div>
              <button onClick={() => this.setState({ showErrorModal: false })}>
                <span>OK</span>
              </button>
            </div>
          </Modal>

          {showLogModal ? (
            <Modal
              width={"70%"}
              title="Logs"
              centered
              visible={showLogModal}
              footer={null}
              onCancel={() => this.setState({ showLogModal: false })}
            >
              <Table
                loading={contentLoading}
                columns={logsColoumn}
                pagination={false}
                dataSource={parts}
              />
            </Modal>
          ) : null}
        </div>
      );
    }
  }
}

export default OperatorCabTrip;
