import React, { Children, Component } from "react";
import { Select, Tag, Table, Button, Modal, message, Input, Image } from "antd";
import { Link } from "react-router-dom";

import jwt from "jsonwebtoken";

import Loader from "../loader";
import Fetch from "../../utils/fetch";
import CrewTicketSearch from "../../components/crewTicketSearch";
import moment from "moment";
import {
  EditOutlined,
  PlusCircleOutlined,
  PlayCircleOutlined
} from "@ant-design/icons";
const { Option } = Select;
const wordBreakStyle = { wordBreak: "breakWord" };
const format = "HH:mm";

const serviceNameFetched = [];
function msToTime(duration) {
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  let days = Math.floor(duration / (1000 * 60 * 60 * 24));
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return days + " days: " + hours + " hrs :" + minutes + " min";
}

const callDetailsCol = [
  {
    title: "Time",
    dataIndex: "time",
    width: "10%",
    key: "time"
  },
  {
    title: "Duration",
    dataIndex: "duration",
    width: "10%",
    key: "duration"
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "10%",
    key: "status"
  },
  {
    title: "Recording",
    dataIndex: "recording",
    width: "10%",
    key: "recording",
    render: ({ recording }) => {
      return (
        <Link to={recording} target="_blank">
          <PlayCircleOutlined />
        </Link>
      );
    }
  }
];

const columns = [
  {
    title: "Service Name",
    dataIndex: "serviceName",
    key: "serviceName",
    filterSearch: true,
    filters: serviceNameFetched,
    onFilter: (value, record) => record.serviceName.includes(value),
    width: "10%"
  },
  {
    title: "Raised By",
    dataIndex: "raisedBy",
    key: "raisedBy",
    width: "10%"
  },
  {
    title: "Complain Category",
    dataIndex: "complainCategory",
    key: "complainCategory",
    width: "10%"
  },
  {
    title: "PNR",
    dataIndex: "bookingCode",
    key: "bookingCode",
    width: "10%"
  },
  {
    title: "Seat number",
    dataIndex: "seat",
    key: "seat",
    width: "10%"
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "10%"
  },
  {
    title: "Attachment",
    dataIndex: "attachment",
    key: "attachments",
    width: "14%",
    render: ({ showModal, isAttachmentAvailable }) => {
      return (
        <>
          <Button disabled={isAttachmentAvailable} onClick={showModal}>
            Show Attachment
          </Button>
        </>
      );
    }
  },
  {
    title: "Call Logs",
    dataIndex: "calllogs",
    key: "calllogs",
    width: "10%",
    render: ({ showModal, show, recordingUrl }) => {
      if (recordingUrl) {
        return (
          <div>
            <Button onClick={() => window.open(recordingUrl)}>
              Show Recording
            </Button>
          </div>
        );
      } else {
        return (
          <>
            <Button disabled={show} onClick={showModal}>
              Show Logs
            </Button>
          </>
        );
      }
    }
  },
  {
    title: "Call",
    dataIndex: "call",
    key: "call",
    width: "14%",
    render: ({ showModal }) => {
      return (
        <>
          <Button onClick={showModal}>Connect to marshal</Button>
        </>
      );
    }
  },
  {
    title: "Created On",
    dataIndex: "createdOn",
    key: "createdOn",
    width: "10%"
  },
  {
    title: "TAT",
    dataIndex: "tat",
    key: "tat",
    width: "10%"
  },
  {
    title: "Solution Time",
    dataIndex: "solTime",
    key: "solTime",
    width: "10%"
  },
  {
    title: "Add Solution Time",
    dataIndex: "add",
    key: "add",
    width: "10%",
    render: ({ solutionTimeUpdate, _id, raisedBy, serviceId }) => {
      return (
        <>
          <PlusCircleOutlined
            onClick={() => solutionTimeUpdate({ _id, raisedBy, serviceId })}
          />
        </>
      );
    }
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
    width: "10%",
    render: ({ value, _id, allRemarks, remarksUpdate, serviceId }) => (
      <>
        <EditOutlined
          onClick={() => remarksUpdate({ allRemarks, value, _id, serviceId })}
        />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "Marshal remark",
    dataIndex: "marshallRemarks",
    key: "marshallRemarks",
    width: "10%",
    render: ({
      value,
      _id,
      raisedBy,
      allMarshallRemarks,
      marshallRemarksUpdate,
      serviceId
    }) => (
      <>
        <EditOutlined
          onClick={() =>
            marshallRemarksUpdate({
              allMarshallRemarks,
              value,
              _id,
              raisedBy,
              serviceId
            })
          }
        />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "CX Team Remarks",
    dataIndex: "customerRemarks",
    key: "customerRemarks",
    width: "12%",
    render: ({
      value,
      _id,
      raisedBy,
      allCustomerRemarks,
      marshallCtxShow,
      serviceId
    }) => (
      <>
        <span style={wordBreakStyle}>{value}</span>
        <br />
        <PlusCircleOutlined
          onClick={() =>
            marshallCtxShow({
              allCustomerRemarks,
              value,
              _id,
              raisedBy,
              serviceId
            })
          }
        />
      </>
    )
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
    width: "10%",
    render: ({ serviceId, department, raisedBy, _id, updateDepartment }) => {
      if (department == "Crew TICKET") {
        return <div>Crew Team</div>;
      } else {
        return (
          <Select
            value={department}
            style={{ width: 120, margin: "0 0.2rem", whiteSpace: "pre-line" }}
            onChange={e =>
              updateDepartment({
                serviceId,
                department: e,
                raisedBy,
                _id
              })
            }
          >
            <Option value="Crew Team">Crew Team</Option>
            <Option value="CX Team">CX Team</Option>
          </Select>
        );
      }
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ status, raisedBy, _id, updateStatus, serviceId }) => {
      return (
        <Select
          value={status}
          style={{ width: 120, margin: "0 0.2rem", whiteSpace: "pre-line" }}
          onChange={e =>
            updateStatus({
              status: e,
              raisedBy,
              _id,
              serviceId
            })
          }
        >
          <Option value="REJECTED">REJECTED</Option>
          <Option value="CLOSED">CLOSED</Option>
          <Option value="INPROGRESS">INPROGRESS</Option>
          <Option value="CREATED">CREATED</Option>
        </Select>
      );
    }
  }
];

class complainView extends Component {
  state = {
    loading: true,
    showRemarksEdit: false,
    showSolutionTimeEdit: false,
    minutes: 0,
    hours: 0,
    day: 0,
    showAttachments: false,
    attachmentPath: "",
    showLogs: false,
    showCall: false,
    showMarshallCall: false,
    remarkInput: ""
  };

  async componentDidMount() {
    try {
      serviceNameFetched.length = 0;
      const complainResponse = await this.fetchComplain();
      let serviceNameHashMap = {};
      for (let i = 0; i < complainResponse.length; i++) {
        let fetchedData = complainResponse[i];
        serviceNameHashMap[fetchedData.serviceName] = [];
      }

      for (let i in serviceNameHashMap) {
        let obj = {
          text: i,
          value: i
        };
        serviceNameFetched.push(obj);
      }

      this.setState({ complainResponse, loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  fetchComplain = async () => {
    try {
      const url = `/bus-tracker/complianSearch`;
      const options = {
        method: "post"
      };
      const response = await Fetch(url, options);
      if (response && Array.isArray(response)) {
        return this.formattedData(response);
      }
      return null;
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  formattedData = response => {
    try {
      let formetedRep = [],
        check = [],
        hashMap = {};
      for (let i = 0; i < response.length; i++) {
        let complains = response[i];
        let latestValue = complains.remarks ? complains.remarks.split("|") : "";
        latestValue = latestValue ? latestValue.filter(obj => obj != "") : "";

        let latestValueMarshallRemark = complains.marshallRemark
          ? complains.marshallRemark.split("|")
          : "";
        latestValueMarshallRemark = latestValueMarshallRemark
          ? latestValueMarshallRemark.filter(obj => obj != "")
          : "";
        let latestValueCtxRemark = complains.customerRemarks
          ? complains.customerRemarks.split("|")
          : "";
        latestValueCtxRemark = latestValueCtxRemark
          ? latestValueCtxRemark.filter(obj => obj != "")
          : "";

        const complainFormated = {
          key: complains._id,
          _id: complains._id,
          complainCategory: complains.complainCategory,
          description: complains.description,
          createdOn: moment(complains.createdOn).format("LLL"),
          tat: msToTime(complains.tat),
          tatInMillis: complains.tat,
          solTime: moment(complains.solutionTime).format("LLL"),
          add: {
            _id: complains._id,
            solutionTimeUpdate: this.solutionTimeUpdate,
            serviceId: complains.serviceId
          },
          remarks: {
            _id: complains._id,
            value: latestValue[latestValue.length - 1],
            allRemarks: complains.remarks,
            serviceId: complains.serviceId,
            remarksUpdate: this.remarksUpdate
          },
          marshallRemarks: {
            _id: complains._id,
            serviceId: complains.serviceId,
            value:
              latestValueMarshallRemark[latestValueMarshallRemark.length - 1],
            allMarshallRemarks: complains.marshallRemark,
            raisedBy: complains.raisedBy,
            marshallRemarksUpdate: this.marshallRemarksUpdate
          },
          customerRemarks: {
            _id: complains._id,
            serviceId: complains.serviceId,
            value: latestValueCtxRemark[latestValueCtxRemark.length - 1],
            allCustomerRemarks: complains.customerRemarks,
            raisedBy: complains.raisedBy,
            marshallCtxShow: this.marshallCtxShow
          },
          complainComments: complains.complaintName,
          raisedBy: complains.raisedBy,
          serviceName: complains.serviceName,
          serviceId: complains.serviceId,
          seat: complains.seatNumber,
          bookingCode: complains.pnr,
          attachment: {
            isAttachmentAvailable: complains.attachment ? false : true,
            showModal: () =>
              this.showAttachmentModal(
                complains.attachment,
                complains._id,
                complains.attachmentType
              )
          },
          calllogs: {
            recordingUrl: complains.recordingUrl,
            show: complains.callLogsRestriction,
            showModal: () =>
              this.showLogsModal(
                complains.tripId,
                complains.createdOn,
                complains._id
              )
          },
          call: {
            show: complains.callLogsRestriction,
            showModal: () => this.showCallModal(complains.tripId, complains._id)
          },
          department: {
            serviceId: complains.serviceId,
            updateDepartment: this.updateDepartment,
            raisedBy: complains.raisedBy,
            _id: complains._id,
            department: complains.department
          },
          status: {
            serviceId: complains.serviceId,
            updateStatus: this.updateStatus,
            status: complains.status,
            raisedBy: complains.raisedBy,
            _id: complains._id
          }
        };
        let key = `${complains.serviceId}`;
        if (hashMap[key]) {
          hashMap[key].push(complainFormated);
        } else {
          hashMap[key] = [];
          hashMap[key].push(complainFormated);
        }
        formetedRep.push(complainFormated);
      }
      for (let i in hashMap) {
        let a = hashMap[i];
        for (let z = 1; z < a.length; z++) {
          if (a[0].children) {
            a[0].children.push(a[z]);
          } else {
            a[0].serviceName = `${a[0].serviceName} (${a.length} Issue Raised)`;
            a[0].children = [];
            a[0].hasChildren = true;
            a[0].children.push(a[z]);
          }
        }
        check.push(a[0]);
      }

      return check;
    } catch (error) {
      console.log(error);
    }
  };

  remarksUpdate = async obj => {
    let remarksArray = obj.allRemarks ? obj.allRemarks.split("|") : "";
    remarksArray = remarksArray ? remarksArray.filter(obj => obj != "") : "";
    const remarksUpdateObj = {
      allRemarks: remarksArray,
      _id: obj._id,
      serviceId: obj.serviceId
    };
    this.setState({ showRemarksEdit: true, remarksUpdateObj });
  };

  marshallRemarksUpdate = async obj => {
    let allMarshallRemarksArray = obj.allMarshallRemarks
      ? obj.allMarshallRemarks.split("|")
      : "";
    allMarshallRemarksArray = allMarshallRemarksArray
      ? allMarshallRemarksArray.filter(obj => obj != "")
      : "";
    const marshallRemarksUpdateObj = {
      outboundRemark: obj.value,
      allMarshallRemarks: allMarshallRemarksArray,
      raisedBy: obj.raisedBy,
      serviceId: obj.serviceId,
      _id: obj._id
    };
    this.setState({ showMarshallRemarkEdit: true, marshallRemarksUpdateObj });
  };

  marshallCtxShow = async obj => {
    let allCustomerRemarksArray = obj.allCustomerRemarks
      ? obj.allCustomerRemarks.split("|")
      : "";
    allCustomerRemarksArray = allCustomerRemarksArray
      ? allCustomerRemarksArray.filter(obj => obj != "")
      : "";

    const customerRemarkObj = {
      outboundRemark: obj.value,
      allCustomerRemarks: allCustomerRemarksArray,
      raisedBy: obj.raisedBy,
      serviceId: obj.serviceId,
      _id: obj._id
    };

    this.setState({ showCtxRemarks: true, customerRemarkObj });
  };

  updateMarshallRemark = async () => {
    const {
      complainResponse,
      marshallRemarkInput,
      marshallRemarksUpdateObj,
      searchedData
    } = this.state;
    if (marshallRemarkInput.trim().length === 0) {
      message.error("please enter something");
      this.setState({
        showOutBoundsEdit: false,
        marshallRemarkInput: ""
      });
      return;
    }
    let toBeUpdateData = complainResponse.filter(
      comp => comp._id == marshallRemarksUpdateObj._id
    );
    let isChild = false;
    if (toBeUpdateData.length == 0) {
      const serviceBasedFilter = complainResponse.filter(
        comp => comp.serviceId == marshallRemarksUpdateObj.serviceId
      );
      toBeUpdateData = serviceBasedFilter[0].children.filter(
        comp => comp._id == marshallRemarksUpdateObj._id
      );
      isChild = true;
    }
    const url = `/bus-tracker/complianUpdate`;
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);

    let remark = toBeUpdateData[0].marshallRemarks.allMarshallRemarks
      ? `${toBeUpdateData[0].marshallRemarks.allMarshallRemarks} `
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}: ${marshallRemarkInput} |`;
    const options = {
      method: "patch",
      data: {
        updateBody: searchedData,
        _id: marshallRemarksUpdateObj._id,
        marshallRemark: updateData,
        raisedBy: toBeUpdateData[0].raisedBy
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);

      this.setState({
        complainResponse: formattedData,
        marshallRemarksUpdateObj: {},
        showMarshallRemarkEdit: false,
        marshallRemarkInput: ""
      });
      message.success("Data is updated");
    } else {
      message.success("Data not updated");
      this.setState({
        showMarshallRemarkEdit: false,
        marshallRemarkInput: ""
      });
    }
  };

  solutionTimeUpdate = async obj => {
    const soltimeUpdateObj = {
      tat: obj.value,
      raisedBy: obj.raisedBy,
      _id: obj._id,
      serviceId: obj.serviceId
    };
    this.setState({ showSolutionTimeEdit: true, soltimeUpdateObj });
  };

  showAttachmentModal = (attachment, _id, attachmentType) => {
    this.setState({
      showAttachments: true,
      attachmentPath: attachment,
      attachmentType
    });
  };

  showCallModal = async (tripId, _id) => {
    const tripUrl = `/bus-tracker/singleTrip/?tripId=${tripId}`;
    const tripOptions = {
      method: "get"
    };
    let tripResponse = await Fetch(tripUrl, tripOptions),
      marshalObj;
    if (tripResponse) {
      if (!tripResponse.busHost.mobileNo) {
        message.error("Host Not Assigned Yet");
        return;
      }
      marshalObj = {
        _id,
        name: tripResponse.busHost.name,
        service: tripResponse.service.name,
        number: tripResponse.busHost.mobileNo,
        tripDate: moment(parseInt(tripResponse.tripDate)).format("LL")
      };
    }
    this.setState({ marshalObj, showMarshallCall: true });
  };

  showLogsModal = async (tripId, createdOn, _id) => {
    const tripUrl = `/bus-tracker/singleTrip/?tripId=${tripId}`;
    const tripOptions = {
      method: "get"
    };

    let tripResponse = await Fetch(tripUrl, tripOptions);
    if (tripResponse) {
      if (!tripResponse.busHost.mobileNo) {
        message.error("Host Not Assigned Yet");
        return;
      }

      const url = `/bus-tracker/callLogs?number=${tripResponse.busHost.mobileNo}&createdOn=${createdOn}`;
      const options = {
        method: "get"
      };
      let response = await Fetch(url, options);
      if (response.length > 0) {
        let callDetailsObj = [];
        response.map(obj => {
          let callData = {
            time: obj.start_time,
            duration: obj.billsec + " s",
            status: obj.status,
            recording: { recording: obj.recording, status: obj.status }
          };

          callDetailsObj.push(callData);

          this.setState({ callDetailsObj, showLogs: true });
        });
      } else {
        message.error("No Logs Found");
      }
    }
  };

  updateStatus = async obj => {
    const { complainResponse, searchedData } = this.state;
    const url = `/bus-tracker/complianUpdate`;
    let toBeUpdateData = complainResponse.filter(comp => comp._id == obj._id);
    let isChild = false;
    if (toBeUpdateData.length == 0) {
      const serviceBasedFilter = complainResponse.filter(
        comp => comp.serviceId == obj.serviceId
      );
      toBeUpdateData = serviceBasedFilter[0].children.filter(
        comp => comp._id == obj._id
      );
      isChild = true;
    }
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    let remark = toBeUpdateData[0].remarks.allRemarks
      ? `${toBeUpdateData[0].remarks.allRemarks} `
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}:moved from ${toBeUpdateData[0].status.status} to ${obj.status}|`;
    const options = {
      method: "patch",
      data: {
        updateBody: searchedData,
        _id: obj._id,
        raisedBy: obj.raisedBy,
        status: obj.status,
        remark: updateData
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);

      this.setState({
        complainResponse: formattedData
      });
      message.success("Data is updated");
    }
  };

  updateDepartment = async obj => {
    const { complainResponse, searchedData } = this.state;
    const url = `/bus-tracker/complianUpdate`;
    let toBeUpdateData = complainResponse.filter(comp => comp._id == obj._id);
    let isChild = false;
    if (toBeUpdateData.length == 0) {
      const serviceBasedFilter = complainResponse.filter(
        comp => comp.serviceId == obj.serviceId
      );
      toBeUpdateData = serviceBasedFilter[0].children.filter(
        comp => comp._id == obj._id
      );
      isChild = true;
    }
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    let remark = toBeUpdateData[0].remarks.allRemarks
      ? `${toBeUpdateData[0].remarks.allRemarks} `
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}:moved from ${toBeUpdateData[0].department.department} to ${
      obj.department
    }|`;
    const options = {
      method: "patch",
      data: {
        updateBody: searchedData,
        _id: obj._id,
        raisedBy: obj.raisedBy,
        department: obj.department,
        remark: updateData
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);

      this.setState({
        complainResponse: formattedData
      });
      message.success("Data is updated");
    }
  };

  showLoading = () => {
    this.setState({ contentLoading: true });
  };

  updateRemark = async () => {
    const {
      complainResponse,
      remarkInput,
      remarksUpdateObj,
      searchedData
    } = this.state;
    if (remarkInput.trim().length === 0) {
      message.error("please enter something");
      this.setState({
        showRemarksEdit: false,
        remarkInput: ""
      });
      return;
    }
    let toBeUpdateData = complainResponse.filter(
      comp => comp._id == remarksUpdateObj._id
    );
    let isChild = false;
    if (toBeUpdateData.length == 0) {
      const serviceBasedFilter = complainResponse.filter(
        comp => comp.serviceId == remarksUpdateObj.serviceId
      );
      toBeUpdateData = serviceBasedFilter[0].children.filter(
        comp => comp._id == remarksUpdateObj._id
      );
      isChild = true;
    }
    const url = `/bus-tracker/complianUpdate`;
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);

    let remark = toBeUpdateData[0].remarks.allRemarks
      ? `${toBeUpdateData[0].remarks.allRemarks} `
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}: ${remarkInput} |`;
    const options = {
      method: "patch",
      data: {
        updateBody: searchedData,
        _id: remarksUpdateObj._id,
        remark: updateData,
        raisedBy: toBeUpdateData[0].raisedBy
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);

      this.setState({
        complainResponse: formattedData,
        remarksUpdateObj: {},
        showRemarksEdit: false,
        remarkInput: ""
      });
      message.success("Data is updated");
    } else {
      message.success("Data not updated");
      this.setState({
        showRemarksEdit: false,
        remarkInput: ""
      });
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleTextChange = (key, value) => {
    this.setState({ [key]: value });
  };

  updateTime = async () => {
    const {
      complainResponse,
      soltimeUpdateObj,
      searchedData,
      hours,
      day,
      minutes
    } = this.state;
    const url = `/bus-tracker/complianUpdate`;
    let isChild = false;
    let toBeUpdateData = complainResponse.filter(
      obj => soltimeUpdateObj._id == obj._id
    );
    if (toBeUpdateData.length == 0) {
      const serviceBasedFilter = complainResponse.filter(
        comp => comp.serviceId == soltimeUpdateObj.serviceId
      );
      toBeUpdateData = serviceBasedFilter[0].children.filter(
        comp => comp._id == soltimeUpdateObj._id
      );
      isChild = true;
    }
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    let newTat = toBeUpdateData[0].tatInMillis
      ? toBeUpdateData[0].tatInMillis
      : 0;

    const newSolTimehr = parseInt(hours) * 60 * 60 * 1000;
    const newSolTimeMin = parseInt(minutes) * 60 * 1000;
    const newSolTimeday = parseInt(day) * 24 * 60 * 60 * 1000;

    const newSolTime = newSolTimehr + newSolTimeMin + newSolTimeday + newTat;

    let remark = toBeUpdateData[0].remarks.allRemarks
      ? `${toBeUpdateData[0].remarks.allRemarks} `
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}: updated solution time ${
      toBeUpdateData[0].tat
    } to ${day} day:${hours} hours:${minutes} minutes |`;
    const options = {
      method: "patch",
      data: {
        updateBody: searchedData,
        _id: soltimeUpdateObj._id,
        tat: newSolTime,
        remark: updateData,
        raisedBy: toBeUpdateData[0].raisedBy
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);

      this.setState({
        complainResponse: formattedData,
        showSolutionTimeEdit: false,
        minutes: 0,
        hours: 0,
        day: 0
      });
      message.success("Data is updated");
    } else {
      message.error("something went wrong");
    }
  };
  dayPicker = async e => {
    if (e.target.value < 10) {
      this.setState({ day: e.target.value });
    }
  };

  hrPicker = async e => {
    if (e.target.value < 24) {
      this.setState({ hours: e.target.value });
    }
  };

  minutesPicker = async e => {
    if (e.target.value < 60) {
      this.setState({ minutes: e.target.value });
    }
  };

  call = async () => {
    const { bookingcomplain, marshalObj } = this.state;
    const url = `/bus-tracker/camplainCall`;
    const options = {
      method: "post",
      data: {
        number: marshalObj ? marshalObj.number : bookingcomplain.number
      }
    };
    let response = await Fetch(url, options);
    if (response == "ok") {
      message.success("You will recieve call shortly");
      this.setState({
        showMarshallCall: false,
        marshalObj: []
      });
    } else if (response == "error") {
      message.error("There is some error");
      this.setState({
        showMarshallCall: false,
        marshalObj: []
      });
    }
  };

  updateData = async data => {
    try {
      const url = `/bus-tracker/complianSearch`;
      const options = {
        method: "post",
        data
      };
      const response = await Fetch(url, options);
      if (response && Array.isArray(response)) {
        let formattedData = this.formattedData(response);
        this.setState({
          searchedData: data,
          complainResponse: formattedData,
          contentLoading: false
        });
      } else {
        this.setState({
          complainResponse: [],
          contentLoading: false
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        contentLoading: false
      });
      message.error(err);
    }
  };

  render() {
    const {
      loading,
      showRemarksEdit,
      showSolutionTimeEdit,
      contentLoading,
      callDetailsObj,
      remarkInput,
      remarksUpdateObj,
      complainResponse,
      showAttachments,
      attachmentType,
      attachmentPath,
      showLogs,
      showCall,
      minutes,
      hours,
      day,
      showMarshallCall,
      showMarshallRemarkEdit,
      showCtxRemarks,
      marshallRemarksUpdateObj,
      customerRemarkObj,
      marshallRemarkInput,
      marshalObj
    } = this.state;
    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div>
            <CrewTicketSearch
              showLoader={this.showLoading}
              onSearch={this.updateData}
            />
          </div>
          <div
            style={{
              overflow: "hidden",
              width: "100%",
              height: "100vh"
            }}
          >
            <Table
              scroll={{ y: "70vh", x: "150%" }}
              columns={columns}
              loading={contentLoading}
              pagination={false}
              dataSource={complainResponse}
            />
          </div>
          <Modal
            centered
            title="Attachments"
            visible={showAttachments}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                showAttachments: false,
                attachmentPath: "",
                attachmentType: ""
              })
            }
            footer={null}
          >
            {attachmentType == "image" ? (
              <Image width={150} src={attachmentPath} />
            ) : (
              <video width="320" height="240" controls>
                <source src={attachmentPath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Modal>
          <Modal
            centered
            width={"100%"}
            title="Call Logs"
            visible={showLogs}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => this.handleChange("showLogs", false)}
            footer={null}
          >
            <Table
              columns={callDetailsCol}
              dataSource={callDetailsObj}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </Modal>
          <Modal
            centered
            width={"100%"}
            title="Call Marshal"
            visible={showCall}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => this.handleChange("showCall", false)}
            footer={null}
          >
            <span>attachment</span>
          </Modal>
          <Modal
            title="Solution Time Update"
            visible={showSolutionTimeEdit}
            okText="Update"
            width={800}
            onOk={this.updateTime}
            onCancel={() =>
              this.setState({
                showSolutionTimeEdit: false
              })
            }
          >
            <span>Enter day/hrs/mins</span>
            <input
              style={{ margin: "10px 10px" }}
              value={day}
              type="number"
              placeholder="please enter day"
              onChange={e => this.dayPicker(e)}
            ></input>
            <input
              style={{ margin: "10px 10px" }}
              value={hours}
              type="number"
              placeholder="please enter hrs"
              onChange={e => this.hrPicker(e)}
            ></input>
            <input
              value={minutes}
              type="number"
              placeholder="please enter mins"
              onChange={e => this.minutesPicker(e)}
            ></input>
          </Modal>
          <Modal
            title="Remarks"
            visible={showRemarksEdit}
            okText="Update"
            onOk={this.updateRemark}
            onCancel={() =>
              this.setState({
                remarkInput: "",
                showRemarksEdit: false
              })
            }
          >
            {remarksUpdateObj && Array.isArray(remarksUpdateObj.allRemarks)
              ? remarksUpdateObj.allRemarks.map(text => {
                  return (
                    <div>
                      <p>{text}</p>
                    </div>
                  );
                })
              : null}
            <Input
              value={remarkInput}
              placeholder="Enter Remark"
              onChange={e =>
                this.handleTextChange("remarkInput", e.target.value)
              }
            />
          </Modal>

          <Modal
            title="Customer Remarks"
            visible={showCtxRemarks}
            footer={null}
            onCancel={() =>
              this.setState({
                showCtxRemarks: false
              })
            }
          >
            {customerRemarkObj &&
            Array.isArray(customerRemarkObj.allCustomerRemarks)
              ? customerRemarkObj.allCustomerRemarks.map(text => {
                  return (
                    <div>
                      <p>{text}</p>
                    </div>
                  );
                })
              : null}
          </Modal>

          <Modal
            title="Marshal Remarks"
            visible={showMarshallRemarkEdit}
            okText="Update"
            onOk={this.updateMarshallRemark}
            onCancel={() =>
              this.setState({
                marshallRemarkInput: "",
                showMarshallRemarkEdit: false
              })
            }
          >
            {marshallRemarksUpdateObj &&
            Array.isArray(marshallRemarksUpdateObj.allMarshallRemarks)
              ? marshallRemarksUpdateObj.allMarshallRemarks.map(text => {
                  return (
                    <div>
                      <p>{text}</p>
                    </div>
                  );
                })
              : null}

            <Input
              value={marshallRemarkInput}
              placeholder="Enter Remark"
              onChange={e =>
                this.handleTextChange("marshallRemarkInput", e.target.value)
              }
            />
          </Modal>
          <Modal
            title=" Marshal Details"
            visible={showMarshallCall}
            centered
            onCancel={() =>
              this.setState({
                showMarshallCall: false,
                marshalObj: []
              })
            }
            closable={false}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <div>
              <p>
                Marshal Name:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {marshalObj ? marshalObj.name : ""}
                </b>
              </p>
              <p>
                {" "}
                Service:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {marshalObj ? marshalObj.service : ""}
                </b>
              </p>
              <p>
                Trip Date:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {marshalObj ? marshalObj.tripDate : ""}
                </b>
              </p>
            </div>
            <b
              style={{
                fontSize: "15px"
              }}
            >
              {" "}
              Are you sure you want to place a Call to the Marshal ?
            </b>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                type="primary"
                onClick={this.call}
                style={{ marginRight: "5px" }}
              >
                Call
              </Button>
              <Button
                onClick={() =>
                  this.setState({
                    showMarshallCall: false,
                    marshalObj: []
                  })
                }
                type="ghost"
              >
                Cancel
              </Button>
            </div>
          </Modal>
        </div>
      );
    }
  }
}

export default complainView;
