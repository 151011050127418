import React, { useEffect, useState } from "react";
import KycPolicyHindi from "./policyHindi";
import KycPolicyEnglish from "./policyEnglish";
import Loader from "../../components/loader";
import styles from "./kycPolicy.module.scss";

const allLanguage = { hindi: "hindi", english: "english" };

const KycPolicy = props => {
  const [language, updateLanguage] = useState(allLanguage.english);
  const [loading, updateLoading] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);

    const language = query.get("ln");

    const validLanguage = allLanguage[language];

    if (validLanguage) {
      updateLanguage(language);
      updateLoading(false);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (language === allLanguage.hindi) {
    return (
      <div className={styles.kycPolicyContainer}>
        <KycPolicyHindi />
      </div>
    );
  }

  return (
    <div className={styles.kycPolicyContainer}>
      <KycPolicyEnglish />
    </div>
  );
};

export default KycPolicy;
