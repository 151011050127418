import React, { Component } from "react";
import moment from "moment";
import less from "./search.module.scss";
import { Button, DatePicker, Select, message, Modal } from "antd";
import Fetch from "../../../utils/fetch";

const { Option } = Select;

class OperatorSearch extends Component {
  state = {
    tripType: "inter",
    buttonLoading: false
  };

  async componentDidMount() {
    try {
      const stations = await this.fetchStations();
      const services = await this.fetchServices();
      this.setState({ services, stations });
    } catch (err) {
      message.error(err);
    }
  }

  fetchStations = async () => {
    try {
      const url = `/bus-tracker/getStationsCache`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  fetchServices = async () => {
    try {
      const url = `/bus-tracker/getServicesCache`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  search = async body => {
    try {
      const { isFirstMile } = this.props;
      const url = `/cabOperator/serviceSearch`;
      let details = {
        ...body,
        serviceType: isFirstMile ? "FIRSTMILE" : "LASTMILE"
      };
      const options = {
        method: "post",
        data: { details }
      };
      const response = await Fetch(url, options);
      this.setState({ buttonLoading: false });

      if (response) {
        this.props.onSearch(response);
      } else {
        this.props.onSearch([]);
      }
    } catch (err) {
      console.log(err);
      this.setState({ buttonLoading: false });
      this.props.onSearch([]);
    }
  };
  serviceSearch = () => {
    this.setState({ buttonLoading: true });
    const { serviceNameId } = this.state;
    if (!serviceNameId) {
      message.error("Please select service first");
      return;
    }
    const body = {
      serviceNameId
    };
    this.search(body);
  };

  stationSearch = () => {
    this.setState({ buttonLoading: true });
    const { changeStationSelectedId } = this.state;
    if (!changeStationSelectedId) {
      message.error("Please select station first");
      return;
    }
    const body = {
      changeStationSelectedId
    };
    this.search(body);
  };

  dateSearch = () => {
    this.setState({ buttonLoading: true });

    const { fromDate, toDate, tripType } = this.state;
    const date = new Date(fromDate);
    const fromDateMillis = date.getTime();

    const toDateConvert = new Date(toDate);
    const toDateMillis = toDateConvert.getTime();

    const body = {
      fromDateMillis,
      tripType,
      toDateMillis
    };
    this.search(body);
  };

  onOk = value => {
    console.log("onOk: ", value);
  };

  handleChange = async (key, value, data) => {
    if (key === "serviceSelected") {
      this.setState({
        serviceName: value,
        serviceNameId: data.key
      });
    } else if (key === "changeStationSelected") {
      this.setState({
        changeStationSelected: value,
        changeStationSelectedId: data.key
      });
    } else this.setState({ [key]: value });
  };

  render() {
    const {
      fromDate,
      toDate,
      buttonLoading,
      stations = [],
      changeStationSelected,
      serviceName,
      services = []
    } = this.state;
    return (
      <>
        <div className={less.OperatorSearch}>
          <div className={less.selectables}>
            <Select
              placeholder="Service Name"
              showSearch
              style={{ width: 350 }}
              value={serviceName}
              onChange={(value, key) =>
                this.handleChange("serviceSelected", value, key)
              }
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {services.map(s => (
                <Option value={s.name} key={s._id}>
                  {s.name}
                </Option>
              ))}
            </Select>
            <button
              disabled={buttonLoading}
              className={less.searchBtn}
              onClick={this.serviceSearch}
            >
              <span>Search</span>
            </button>
          </div>

          <div className={less.selectables}>
            <Select
              showSearch
              style={{ width: 350 }}
              value={changeStationSelected}
              onChange={(value, key) =>
                this.handleChange("changeStationSelected", value, key)
              }
              placeholder="Change Station"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {stations.map(s => (
                <Option value={s.name} key={s._id}>
                  {s.name}
                </Option>
              ))}
            </Select>
            <button
              disabled={buttonLoading}
              className={less.searchBtn}
              onClick={this.stationSearch}
            >
              <span>Search</span>
            </button>
          </div>

          <div className={less.dateSelect}>
            <DatePicker
              value={fromDate}
              onChange={fromDate => this.setState({ fromDate })}
            />
            {" to "}
            <DatePicker
              value={toDate}
              onChange={toDate => this.setState({ toDate })}
            />
          </div>

          <div className={less.selectables}>
            <Select
              style={{ width: 150 }}
              defaultValue={"inter"}
              placeholder="Select"
              onChange={value => this.setState({ tripType: value })}
            >
              <Option value="inter">Inter</Option>
              <Option value="intra">Intra</Option>
            </Select>
          </div>
          <button
            disabled={buttonLoading}
            className={less.searchBtn}
            onClick={this.dateSearch}
          >
            <span>Search / Refresh</span>
          </button>
        </div>
      </>
    );
  }
}

export default OperatorSearch;
